(function (w, d, n) {
    'use strict';

    w.Tollwerk.initializePropertyMap = function () {
        const mapElement = d.getElementById('propertyMap');
        if (mapElement) {
            const latitudeField = d.querySelector('#proposalForm-coordinates_latitude');
            const longitudeField = d.querySelector('#proposalForm-coordinates_longitude');
            const mapZoom = mapElement.getAttribute('data-zoom');
            const mapCenter = mapElement.hasAttribute('data-center') ?
                JSON.parse(mapElement.getAttribute('data-center')) :
                { lat: window.Tollwerk.position.latitude, lon: window.Tollwerk.position.longitude };
            const mapOptions = {
                id: 'mapbox.streets',
                noWrap: true,
                inertia: true,
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                accessToken: 'pk.eyJ1IjoidG9sbHdlcmsiLCJhIjoiY2pubjByanVhMDl6cjNwbWI2bmd3djJ1bCJ9.hW-3b9ymt8T64DNC-fE62w',
                //urlTemplate: 'https://{s}.tile.openstreetmap.se/hydda/full/{z}/{x}/{y}.png',
                urlTemplate: 'https://api.mapbox.com/styles/v1/tollwerk/cjnvqy0hi5r3i2rpe462gl6d7/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidG9sbHdlcmsiLCJhIjoiY2tiMHg3MGZ1MDU4cTJxbGM2anJwem9uNiJ9.uN2tmZrJhsLPiqL3E5qiTQ',
                inertiaDeceleration: 2000
            };

            const propertySettings = new w.Tollwerk.twMap('OpenStreetMaps', mapElement, mapZoom, mapCenter, mapOptions, false);
            propertySettings.map.on('click', function (e) {
                propertySettings.removeMarkers();

                latitudeField.value = e.latlng.lat.toFixed(6);
                longitudeField.value = e.latlng.lng.toFixed(6);

                const coords = [e.latlng.lat, e.latlng.lng];
                propertySettings.setPropertyView(coords, mapZoom);
                propertySettings.createPropertyMarker(coords, propertySettings.customMarkers.userMarker);
            });

            // Input Fields

            const coordsFields = [latitudeField, longitudeField];

            coordsFields.map(function (coordsField) {
                coordsField.addEventListener('input', function () {
                    let newCoords = [];
                    coordsFields.map(function (coordsField) {
                        newCoords.push(coordsField.value);
                    });

                    propertySettings.removeMarkers();
                    propertySettings.setPropertyView(newCoords, mapZoom);
                    propertySettings.createPropertyMarker(newCoords, propertySettings.customMarkers.userMarker);
                });
            });

            if (n.geolocation && !mapCenter.length) {
                n.geolocation.getCurrentPosition(function (position) {
                    let mapCenter = [position.coords.latitude, position.coords.longitude];
                    propertySettings.setPropertyView(mapCenter, mapZoom);
                    propertySettings.createPropertyMarker(mapCenter, propertySettings.customMarkers.userMarker);
                }, function (e) {
                    console.log('Error Callback: ', e);
                }, {
                    enableHighAccuracy: true,
                    maximumAge: Infinity,
                    timeout: 5000
                });
            } else {
                // propertySettings.setPropertyView(mapCenter, mapZoom);
                // propertySettings.createPropertyMarker(mapCenter, propertySettings.customMarkers.userMarker);
                propertySettings.setPropertyView({ lat: latitudeField.value, lon: longitudeField.value }, mapZoom);
                propertySettings.createPropertyMarker({
                    lat: latitudeField.value,
                    lon: longitudeField.value
                }, propertySettings.customMarkers.userMarker);
            }
        }
    }

})(typeof global !== 'undefined' ? global : window, document, navigator);
