'use strict';

(function (w, d) {
    w.Tollwerk.twPasswordToggle = () => {
        // TODO: Implement in modal
        const passwordInput = d.querySelector('#component-password');
        if (passwordInput) {
            const passwordSwitch = d.querySelector('.Icon__switch');
            passwordSwitch.addEventListener('click', function () {
                if (passwordInput.type === 'password') {
                    passwordInput.type = 'text';
                } else {
                    passwordInput.type = 'password';
                }
            });
        }
    }
})(window, document);
