(function (w, d) {
    'use strict';

    /**
     * Banner Manager
     *
     * @constructor
     */
    function BannerManager() {
        this.banners = {};
        this.stash = [];
        this.container = null;
        this.active = null;
        this.delay = 3000;
        this.animation = 300;
        Tollwerk.Init.registerOnDisplay(this.initialize.bind(this))
    }

    /**
     * Initialize the banner manager
     */
    BannerManager.prototype.initialize = function () {
        this.container = document.querySelector('.BannerManager');
        if (this.container) {
            this.container.classList.add('BannerManager--hide');
            this.container.querySelectorAll('.Banner').forEach(function (banner) {
                if (banner.id) {
                    this.banners[banner.id] = banner;
                    banner._auto = banner.classList.contains('Banner--auto');
                    if (banner._auto) {
                        this.stash.push(banner.id);
                    }
                }
            }, this);
        }
        this.next();
    }

    /**
     * Dismiss a banner by ID
     *
     * @param {String} id Banner id
     */
    BannerManager.prototype.dismiss = function (id) {
        if (id in this.banners) {
            this.container.classList.add('BannerManager--hide');
            this.container.classList.remove('BannerManager--show');
            var banner = this.banners[id];
            delete this.banners[id];
            setTimeout(function () {
                this.active = null;
                banner.parentNode.removeChild(banner);
                this.next();
            }.bind(this), this.animation);
        }
    }

    /**
     * Move on to the next banner (stashed or next auto)
     */
    BannerManager.prototype.next = function () {
        if ((this.active === null) && (this.stash.length)) {
            this.active = this.stash.shift();
            setTimeout(this.activate.bind(this, this.active), this.delay);
        }
    }

    /**
     * Queue a particular banner
     *
     * @param {String} id Banner ID
     */
    BannerManager.prototype.queue = function (id) {
        if (id && (id in this.banners)) {
            this.stash.push(id);
            this.next();
        }
    }

    /**
     * Activate a particular banner
     *
     * @param {String} id Banner ID
     */
    BannerManager.prototype.activate = function (id) {
        if (this.active !== null) {
            this.banners[this.active].classList.add('Banner--active');
            this.container.classList.add('BannerManager--show');
            this.container.classList.remove('BannerManager--hide');
        }
    }

    // Export as CommonJS module
    if (typeof exports !== 'undefined') {
        exports.Banner = new BannerManager();

        // Else create a global instance
    } else {
        w.Tollwerk.Banner = new BannerManager();
    }
})(typeof global !== 'undefined' ? global : this, document);
