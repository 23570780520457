window.addEventListener('load', function () {
    function clearPagesCache() {
        try {
            localStorage.clear();
            if (navigator.serviceWorker && navigator.serviceWorker.controller) {
                navigator.serviceWorker.controller.postMessage('clear-pages');
            }
        } catch (exception) {
            // No support for cached pages listing
        }
    }

    const cookieConsentBtn = document.querySelector('.Banner--cookie-consent button');
    if (cookieConsentBtn) {
        cookieConsentBtn.onclick = function (e) {
            e.preventDefault();
            var expire = new Date();
            var now = expire.toISOString();
            expire.setUTCFullYear(expire.getUTCFullYear() + 1);
            document.cookie = 'cookie-consent=' + now + ';path=/;max-age=31536000;expires=' + expire.toUTCString() + ';secure';
            Tollwerk.Banner.dismiss('cookie-consent');
            clearPagesCache();
        }
    }

    // document.onclick = function () {
    //     clearPagesCache();
    //     Tollwerk.Banner.dismiss('cookie-consent');
    // }

    const addToHomescreenAcceptBtn = document.getElementById('add-to-homescreen-accept');
    if (addToHomescreenAcceptBtn) {
        Tollwerk.AddToHomescreen.handle(function (e) {
            Tollwerk.Banner.queue('add-to-homescreen');
            addToHomescreenAcceptBtn.onclick = function () {
                e.prompt();
                e.userChoice.then((choiceResult) => {
                    Tollwerk.AddToHomescreen.deferred = null;
                    clearPagesCache();
                });
                Tollwerk.Banner.dismiss('add-to-homescreen');
            }
        })
    }
    const addToHomescreenDismissBtn = document.getElementById('add-to-homescreen-dismiss');
    if (addToHomescreenDismissBtn) {
        addToHomescreenDismissBtn.onclick = function () {
            var expire = new Date();
            var now = expire.toISOString();
            expire.setUTCDate(expire.getUTCDate() + 1);
            document.cookie = 'add-to-homescreen=' + now + ';path=/;max-age=31536000;expires=' + expire.toUTCString() + ';secure';
            Tollwerk.Banner.dismiss('add-to-homescreen');
        }
    }
});
