(function () {
    'use strict';

    function makeFixed() {
        // Delta top
        var header = document.getElementById('menu');
        var headerHeight = header ? header.offsetHeight : 0;

        var locationSearch = document.querySelector('.PageMain .LocationSearch');
        locationSearch.style.top = headerHeight + 'px';
        var locationSearchHeight = locationSearch ? locationSearch.offsetHeight : 0;

        var deltaTop = headerHeight + locationSearchHeight;

        // Delta bottom
        var locationFooter = document.getElementById('location-footer');
        var locationFooterHeight = locationFooter ? locationFooter.offsetHeight : 0;
        var deltaBottom = locationFooterHeight;

        // Set delta for main content
        var main = document.getElementById('main');
        if (main) {
            main.style.paddingTop = deltaTop + 'px';
            main.style.paddingBottom = deltaBottom + 'px';
        }


        console.log(deltaTop, deltaBottom);
        document.documentElement.classList.add('fixed-elements');
    }

    window.addEventListener('load', function () {
        // makeFixed();
        return false;

    });
})();

