

window.onload = function() {
    initialiseSlider();
}


function initialiseSlider(element) {
    /*
     * container where the script searches for a .SliderContainer
     * if empty defaults to entire document
    */
    element = (element ? document.querySelector(element) : document);

    if(!element) {
        return false;
    }

    /* all sliders on a page */
    var sliders = element.querySelectorAll('.SliderContainer');

    sliders.forEach(function (item){
        var thisSlider = item.querySelector('.Slider');
        var numberItems = thisSlider.dataset.tnsItems; //number of items shown at a time
        var navItems = item.querySelectorAll('.nav--item');
        var gutter = ((numberItems > 1)? 20 : 0);

        if(!thisSlider.querySelector('.Slider--item')) {
            item.classList.add('empty');
            return false;
        }

        /* initiate a specific slider */
        var slider = tns({
            container: thisSlider,
            nav: false,
            items: numberItems,
            slideBy: 'page',
            controls: false,
            touch: true,
            mouseDrag: true,
            swipeAngle: 45,
            autoHeight: true,
            gutter: gutter
        });
        var slides = slider.getInfo().slideItems;

        deactivateLinks(slides);
        activateLinks(slides[1]);


        slider.events.on('dragEnd', function(){
            removeActive(navItems);

            var index = slider.getInfo().index - slider.getInfo().cloneCount;
            var indexCached = slider.getInfo().indexCached - slider.getInfo().cloneCount;


            if (index == slider.getInfo().slideCount || index > slider.getInfo().slideCount) {
                setActive(navItems[0]);
                activateLinks(slides[1]);
            } else if ( index < 0) {
                activateLinks(slides[slider.getInfo().slideCount]);
                setActive(navItems[slider.getInfo().slideCount + index]);
            } else {
                if(indexCached > index) {
                    setActive(navItems[index]);
                    activateLinks(slides[index]);
                } else {
                    setActive(navItems[index]);
                    activateLinks(slides[indexCached + 1]);
                }
            }
        })

        /* next button */
        var next = item.querySelector('.next');
        if(next) {
            next.addEventListener('click', function () {
                slider.goTo('next');
                removeActive(navItems);
                deactivateLinks(slides);

                if (slider.getInfo().index == slider.getInfo().slideCount + 1) {
                    setActive(navItems[0]);
                    activateLinks(slides[1]);
                } else {
                    setActive(navItems[slider.getInfo().indexCached]);
                    activateLinks(slides[slider.getInfo().indexCached + 1]);
                }
            });
        }

        /* previous button */
        var prev = item.querySelector('.prev');
        if(prev) {
            prev.addEventListener('click', function () {

                slider.goTo('prev');
                removeActive(navItems);
                deactivateLinks(slides);

                if (slider.getInfo().index == 0) {
                    setActive(navItems[slider.getInfo().slideCount - 1]);
                    activateLinks(slides[slider.getInfo().slideCount]);
                } else {
                    setActive(navItems[slider.getInfo().index - 1]);
                    activateLinks(slides[slider.getInfo().index]);
                }
            });
        }

        /* circles below the slider */
        if(navItems) {
            navItems.forEach(function (navItem) {
                navItem.addEventListener('click', function () {
                    slider.goTo(navItem.dataset.index);
                    removeActive(navItems);
                    setActive(this);

                })
            })

        }
    });
}

/* activate links inside slide */

function activateLinks(slide){
    slide.querySelectorAll('a').forEach(function(link){
        link.setAttribute("tabindex", "0");
    })
}

/*deactivate links inside slide */
function deactivateLinks(slides) {
    for(var i = 0; i < slides.length; i++) {
        var links = slides[i].querySelectorAll('a');
        links.forEach(function(link){
            link.setAttribute("tabindex", "-1");
        })
    }

}


/* set the active navigation circle */

function removeActive(navItems){
    navItems.forEach(function(item) {
       item.classList.remove('active');
    });
}

function setActive(navItem){
    navItem.classList.add('active');
}

