TwMobilePanels = function () {
    this.panels = [];
    this.getPanels();
    this.addLinkListeners();
}

TwMobilePanels.prototype.getPanels = function () {
    var panels = document.querySelectorAll('.MobilePanel');
    for (var i = 0, len = panels.length; i < len; i++) {
        var panel = panels[i];
        panel.classList.add('js');
        var panelPrev = panel.querySelector('.MobilePanel__prev');
        if (panelPrev) {
            panelPrev.addEventListener('click', (function (panel) {
                return function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    this.close('#' + panel.id);
                }
            }(panel)).bind(this));
        }

        var panelNext = panel.querySelector('.MobilePanel__next');
        if (panelNext) {
            panelNext.addEventListener('click', (function (panel) {
                return function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    var panelHash = event.currentTarget.hash;
                    if(this.panels.hasOwnProperty(panelHash)){
                        this.open(panelHash);
                        return true;
                    }
                    this.close('#' + panel.id);
                }
            }(panel)).bind(this));
        }
        this.panels['#' + panel.id] = panel;
    }
}

TwMobilePanels.prototype.addPrevListener = function (event) {

}

TwMobilePanels.prototype.addLinkListeners = function () {
    var links = document.querySelectorAll('.MobilePanelLink');
    for (var i = 0, len = links.length; i < len; i++) {
        links[i].removeEventListener('click', TwMobilePanels._handle_link_click.bind(this));
        links[i].addEventListener('click', TwMobilePanels._handle_link_click.bind(this));
    }
}

TwMobilePanels._handle_link_click = function (event) {
    event.preventDefault();
    event.stopPropagation();
    var panelHash = event.currentTarget.hash;
    this.open(panelHash);
}

TwMobilePanels.prototype.close = function (panelHash) {
    if(!this.panels.hasOwnProperty(panelHash)){
        return false;
    }
    this.panels[panelHash].classList.remove('MobilePanel--active');
    return true;
}

TwMobilePanels.prototype.open = function (panelHash) {
    if (!this.panels.hasOwnProperty(panelHash)) {
        return false;
    }
    var panel = this.panels[panelHash];
    panel.classList.add('MobilePanel--active');
    return true;
}

twMobilePanels = function () {
    if (!document.twMobilePanels) {
        document.twMobilePanels = new TwMobilePanels();
    }
    return document.twMobilePanels;
}

window.addEventListener('load', function () {
    var twMobilePanels = new TwMobilePanels();
});
